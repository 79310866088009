<template>
  <div>
    <BaseSection
      v-show="COMPLAIN_STATUS"
      :id="section.key"
      :is-visible="isSectionVisible"
      :is-modified="isDataChanged"
      :section="section"
      @onSectionSaveClick="save"
    >
      <template #section>
        <v-card-text class="mt-4">
           <div class="mb-5">
              <v-expand-transition>
                <v-textarea
                  outlined
                  name="Complain"
                  label="Complain"
                  v-model="complain.text"
                  placeholder="Complain"
                  rows="8"
                  auto-grow
                  :disabled="FIELDS_DISABLED"
                ></v-textarea>
              </v-expand-transition>
           </div>
        </v-card-text>

        <v-file-input
          class="files-input"
          :disabled="fileInProcess || FIELDS_DISABLED"
          multiple
          show-size
          counter
          :loading="fileInProcess"
          chips
          label="Application attachments"
          v-model="uploadedFiles"
        />

        <v-expand-transition>
          <v-card
            class="my-2"
            elevation="0"
            v-show="isUploadedFilesExist"
            outlined
            tile
          >
            <v-card-title>
              Files to upload
            </v-card-title>

            <v-card-text>
              <v-simple-table>
                <template #default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      File Name
                    </th>
                    <th class="text-left">
                      File Description
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(file, fileIdx) in uploadedFiles"
                    :key="fileIdx"
                  >
                    <td>{{ file.name }}</td>
                    <td>
                      <v-text-field
                        label="File Description"
                        v-model="uploadedFilesDescription[fileIdx]"
                        type="text"
                        :disabled="FIELDS_DISABLED"
                      ></v-text-field>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>

            <v-card-actions>
              <v-btn
                v-if="!fileInProcess"
                color="primary"
                @click.prevent="uploadFiles"
                :disabled="FIELDS_DISABLED"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expand-transition>
          <div class="files">
            <v-subheader>YOUR FILES</v-subheader>
            <div class="files-table">
              <div class="files-table__header">
                <span class="files-table__label text-center col-3">File Name</span>
                <span class="files-table__label text-center col-3">File Description</span>
                <span class="files-table__label text-center col-2">Size</span>
                <span class="files-table__label text-center col-2">Created At</span>
                <span class="files-table__label text-center col-1"></span>
                <span class="files-table__label text-center col-1"></span>
              </div>

              <div class="files-table__content">
                <Draggable
                  v-model="storedFilesDraggable"
                  @start="isDragging = true"
                  @end="isDragging = false"
                  :move="changeFileOrder"
                  v-bind="dragOptions"
                  :disabled="FIELDS_DISABLED"
                >
                  <div
                    v-for="(file, fileIdx) in sortedStoredFiles"
                    :key="fileIdx"
                    class="files-table__item"
                  >
                    <div class="col-3">{{ file.filename }}</div>
                    <div class="col-3">
                      <v-text-field class="without-indents"
                        v-model="file.description"
                        @blur="fileDescriptionEdit(file)"
                        type="text"
                        :disabled="FIELDS_DISABLED"
                      ></v-text-field>
                    </div>
                    <div class="col-2">{{ file.size | formatBytes }}</div>
                    <div class="col-2">{{ file.timeCreated }}</div>
                    <div class="col-1">
                      <v-btn
                        :disabled="fileInProcess || FIELDS_DISABLED"
                        @click.prevent="download(file)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-1">
                      <v-btn
                        icon
                        :disabled="fileInProcess || FIELDS_DISABLED"
                        @click.prevent="moveToArchive(file)"
                      >
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </Draggable>
              </div>
            </div>
          </div>
      </template>
    </BaseSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Draggable from 'vuedraggable';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
// eslint-disable-next-line import/named
import {
  FILE_STATUS,
  uploadToComplainFile as uploadFileToStorage,
  updateFile as updateFileToStorage,
  downloadFile,
  saveOrderFile,
  getComplainFileByApplicationId,
  archiveFile,
} from '@/services/firebase';

export default {
  name: 'ComplainSection',

  components: {
    Draggable,
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialComplain: {},
      complain: {},

      isDragging: false,

      filesCount: 0,

      storedFiles: [],

      uploadedFiles: [],
      uploadedFilesDescription: [],

      fileInProcess: false,

      needSaveFilesOrder: false,
    };
  },

  computed: {
    ...mapGetters([
      'APPLICATION_ID',
      'COMPLAIN_STATUS',
      'FIELDS_DISABLED',
    ]),

    sortedStoredFiles() {
      const files = this.sortStoredFiles();

      return files;
    },

    isUploadedFilesExist() {
      const isUploadedFilesExist = Boolean(this.uploadedFiles?.length > 0);

      return isUploadedFilesExist;
    },

    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },

    storedFilesDraggable: {
      get() {
        return this.sortedStoredFiles;
      },

      set(sortedFiles) {
        const saveDelay = 1000;

        const setNewOrderInFile = (file, idx) => {
          const orderFile = { ...file };
          orderFile.order = idx;

          return orderFile;
        };

        const newSortedFiles = sortedFiles.map(setNewOrderInFile);

        this.storedFiles = [...newSortedFiles];

        this.needSaveFilesOrder = true;

        setTimeout(this.saveFilesOrder(this.storedFiles), saveDelay);
      },
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    await this.getFiles();
    this.clearUploadedFiles();

    this.initialComplain = clone(this.existedData);
    this.complain = clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    sortStoredFiles() {
      const sortFiles = (firstFile, secondFile) => firstFile.order - secondFile.order;

      const files = [...this.storedFiles];
      files.sort(sortFiles);

      return files;
    },

    saveFilesOrder(files) {
      const isNotNeedSaveFilesOrder = Boolean(!this.needSaveFilesOrder);
      if (isNotNeedSaveFilesOrder) {
        return;
      }

      files.forEach((item) => {
        saveOrderFile(item);
      });
    },

    async uploadFiles() {
      this.fileInProcess = true;

      const uploadFile = async (file, idx) => {
        const fileMetadata = {
          customMetadata: {
            filename: file.name,
            description: this.uploadedFilesDescription[idx] ?? '',
            status: FILE_STATUS.active,
            order: idx + this.filesCount,
          },
        };

        const filePayload = {
          file,
          metadata: fileMetadata,
          applicationId: this.APPLICATION_ID,
        };

        const fileResponse = await uploadFileToStorage(filePayload);

        this.setFileToStoredFiles(fileResponse.metadata);
      };

      for (let i = 0; i < this.uploadedFiles.length; i += 1) {
        await uploadFile(this.uploadedFiles[i], i);
      }

      this.clearUploadedFiles();

      this.fileInProcess = false;
    },

    setFileToStoredFiles(fileMetadata) {
      const fileName = fileMetadata?.customMetadata?.filename ?? fileMetadata?.filename;
      const isFileNotActive = Boolean(fileMetadata?.customMetadata?.status !== FILE_STATUS.active);

      if (isFileNotActive) {
        return;
      }

      const fileData = {
        path: fileMetadata.fullPath,
        size: fileMetadata.size,
        contentType: fileMetadata.contentType,
        timeCreated: fileMetadata.timeCreated,
        filename: fileName,
        description: fileMetadata?.customMetadata?.description ?? '-',
        order: fileMetadata?.customMetadata?.order,
      };

      this.storedFiles.push(fileData);
    },

    removeFromStoredFiles(removedFile) {
      const findFileByPath = (file) => file.path === removedFile.path;

      const fileIndex = this.storedFiles.findIndex(findFileByPath);

      this.storedFiles.splice(fileIndex, 1);
    },

    async getFiles() {
      const files = await getComplainFileByApplicationId(this.APPLICATION_ID);

      const setStoredFile = (file) => {
        file.getMetadata().then(this.setFileToStoredFiles);
      };

      this.storedFiles = [];
      files.items.forEach(setStoredFile);
    },

    clearUploadedFiles() {
      this.uploadedFiles = [];
    },

    async moveToArchive(file) {
      this.fileInProcess = true;
      await archiveFile(file);

      await this.removeFromStoredFiles(file);
      this.fileInProcess = false;
    },

    async download(file) {
      const fileDownload = downloadFile(file.path);
      const url = await fileDownload.getDownloadURL();

      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', url);
      downloadLink.setAttribute('target', '_blank');
      downloadLink.setAttribute('download', file.filename);

      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);

      downloadLink.click();
      document.body.removeChild(downloadLink);
    },

    changeFileOrder({ relatedContext, draggedContext }) {
      this.needSaveFilesOrder = false;

      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;

      const isNotRelatedElement = Boolean(!relatedElement || !relatedElement.fixed);
      const isFileOrderChanged = Boolean(isNotRelatedElement && !draggedElement.fixed);

      return isFileOrderChanged;
    },

    async fileDescriptionEdit(file) {
      const fileMetadata = {
        customMetadata: {
          filename: file.filename,
          description: file.description,
          status: FILE_STATUS.active,
          order: file.order,
        },
      };
      const filePayload = {
        metadata: fileMetadata,
        path: file.path,
      };
      await updateFileToStorage(filePayload);
    },
  },
};
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #bbb;
}

.files-input {
  padding-left: 16px;
  padding-right: 16px;
}

.flip-list-move {
  transition: transform 0.3s;
}

.without-indents {
  margin: 0;
  padding: 0;
}

.no-move {
  transition: transform 0s;
}

.list-group-item {
  cursor: move;
}

.files {
  display: flex;
  flex-direction: column;

  &-table {
    display: flex;
    flex-direction: column;
    padding: 0 16px;

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 0;
    }
  }
}
</style>
